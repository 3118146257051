(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet/assets/javascripts/wallet-services.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet/assets/javascripts/wallet-services.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  getSpecificDrawName
} = svs.utils.products;
const {
  get
} = svs.components.account_balance;
const fetchMarketplacePlayerWallets = (productId, drawNumber) => new Promise((resolve, reject) => {
  jupiter.get("/marketplace/1/activateddraw/balance?product=".concat(getSpecificDrawName(productId), "&drawNumber=").concat(drawNumber), data => {
    resolve(data === null || data === void 0 ? void 0 : data.activatedDraws);
  }, error => {
    reject(error);
  });
});
const fetchMarketplaceRetailWallet = activatedDrawId => new Promise((resolve, reject) => {
  jupiter.get("/retailer/1/marketplace/activateddraws/".concat(activatedDrawId), response => {
    resolve(response.activatedDraw);
  }, error => {
    reject(error);
  });
});
const fetchPlayerWallet = () => new Promise((resolve, reject) => {
  get((balance, error) => {
    if (error) {
      reject(error);
    } else {
      resolve(balance);
    }
  });
});
setGlobal('svs.components.tipsen.wallet.services', {
  fetchMarketplacePlayerWallets,
  fetchMarketplaceRetailWallet,
  fetchPlayerWallet
});

 })(window);