(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet/assets/javascripts/wallet-actions.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet/assets/javascripts/wallet-actions.js');
"use strict";


const {
  createAction,
  createAsyncThunk
} = RTK;
const {
  services
} = svs.components.tipsen.wallet;
const updatePlayerWalletBalance = createAction('wallet/updatePlayerWalletBalance');
const setActiveWallet = createAction('wallet/setActiveWallet');
const setRetailerCommission = createAction('wallet/setRetailerCommission');
const fetchPlayerWallet = createAsyncThunk('wallet/fetchPlayerWallet', () => services.fetchPlayerWallet());
const fetchMarketplacePlayerWallets = createAsyncThunk('wallet/fetchMarketplacePlayerWallets', _ref => {
  let {
    productId,
    drawNumber
  } = _ref;
  return services.fetchMarketplacePlayerWallets(productId, drawNumber);
});
const fetchMarketplaceRetailWallet = createAsyncThunk('wallet/fetchMarketplaceRetailWallet', activatedDrawId => services.fetchMarketplaceRetailWallet(activatedDrawId));
const updateMarketplaceWallet = createAction('wallet/updateMarketplaceWallet');
const removeMarketplaceWallet = createAction('wallet/removeMarketplaceWallet');
setGlobal('svs.components.tipsen.wallet.actions', {
  updatePlayerWalletBalance,
  setActiveWallet,
  fetchPlayerWallet,
  fetchMarketplacePlayerWallets,
  fetchMarketplaceRetailWallet,
  setRetailerCommission,
  updateMarketplaceWallet,
  removeMarketplaceWallet
});

 })(window);