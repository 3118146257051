(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet/assets/javascripts/wallet-reducer.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet/assets/javascripts/wallet-reducer.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  createReducer
} = RTK;
const {
  updatePlayerWalletBalance,
  setActiveWallet,
  fetchPlayerWallet,
  fetchMarketplacePlayerWallets,
  fetchMarketplaceRetailWallet,
  setRetailerCommission,
  updateMarketplaceWallet,
  removeMarketplaceWallet
} = svs.components.tipsen.wallet.actions;
const {
  WALLET_PLAYER,
  WALLET_MARKETPLACE_PLAYER,
  WALLET_MARKETPLACE_RETAILER,
  WALLET_SELFSERVICE
} = svs.components.tipsen.wallet.walletType;
const {
  WALLET_ID_PLAYER,
  WALLET_ID_MARKETPLACE_RETAILER,
  WALLET_ID_SELFSERVICE
} = svs.components.tipsen.wallet.walletId;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const initialState = {
  wallets: {},
  activeWallet: null,
  requests: {}
};
if (isSelfService) {
  initialState.wallets[WALLET_ID_SELFSERVICE] = {
    id: WALLET_ID_SELFSERVICE,
    type: WALLET_SELFSERVICE,
    label: 'Självservice',
    meta: {
      balance: 0
    }
  };
  initialState.activeWallet = WALLET_ID_SELFSERVICE;
}
function isPendingWalletAction(action) {
  return action.type.startsWith('wallet/') && action.type.endsWith('/pending');
}
function isRejectedWalletAction(action) {
  return action.type.startsWith('wallet/') && action.type.endsWith('/rejected');
}
const walletReducer = createReducer(initialState, builder => {
  builder.addCase(updatePlayerWalletBalance, (state, action) => {
    var _state$wallets$WALLET;
    if ((_state$wallets$WALLET = state.wallets[WALLET_ID_PLAYER]) !== null && _state$wallets$WALLET !== void 0 && (_state$wallets$WALLET = _state$wallets$WALLET.meta) !== null && _state$wallets$WALLET !== void 0 && _state$wallets$WALLET.Balance) {
      state.wallets[WALLET_ID_PLAYER].meta.Balance = action.payload;
    }
  }).addCase(fetchPlayerWallet.fulfilled, (state, action) => {
    delete state.requests[action.meta.requestId];
    state.wallets[WALLET_ID_PLAYER] = {
      id: WALLET_ID_PLAYER,
      type: WALLET_PLAYER,
      label: 'Min spelkassa',
      meta: {
        Balance: action.payload
      }
    };
  }).addCase(setActiveWallet, (state, action) => {
    state.activeWallet = action.payload;
  }).addCase(updateMarketplaceWallet, (state, action) => {
    const {
      activatedDrawId,
      balance,
      commissionPercent,
      soldShares
    } = action.payload;
    if (state.wallets[activatedDrawId] && state.wallets[activatedDrawId].type === WALLET_MARKETPLACE_PLAYER) {
      state.wallets[activatedDrawId].meta.Balance = balance;
    }
    if (state.wallets[WALLET_ID_MARKETPLACE_RETAILER]) {
      var _state$wallets$WALLET2, _state$wallets$WALLET3;
      const currentCommission = (_state$wallets$WALLET2 = state.wallets[WALLET_ID_MARKETPLACE_RETAILER]) === null || _state$wallets$WALLET2 === void 0 ? void 0 : _state$wallets$WALLET2.meta.commissionPercent;
      Object.assign((_state$wallets$WALLET3 = state.wallets[WALLET_ID_MARKETPLACE_RETAILER]) === null || _state$wallets$WALLET3 === void 0 ? void 0 : _state$wallets$WALLET3.meta, {
        apiCommission: commissionPercent,
        commissionPercent: typeof currentCommission === 'number' ? Math.min(currentCommission, commissionPercent) : commissionPercent,
        currentPlayableAmount: balance,
        soldShares
      });
    }
  }).addCase(removeMarketplaceWallet, state => {
    const walletKey = state.activeWallet;
    if (state.wallets[state.activeWallet]) {
      delete state.wallets[walletKey];
    }
    if (String(state.activeWallet) === walletKey) {
      state.activeWallet = null;
    }
  }).addCase(fetchMarketplacePlayerWallets.fulfilled, (state, action) => {
    delete state.requests[action.meta.requestId];
    Object.entries(state.wallets).forEach(_ref => {
      let [walletId, wallet] = _ref;
      if (wallet.type === WALLET_MARKETPLACE_PLAYER) {
        delete state.wallets[walletId];
      }
    });
    for (const wallet of action.payload) {
      state.wallets[wallet.ActivatedDrawId] = {
        id: wallet.ActivatedDrawId,
        type: WALLET_MARKETPLACE_PLAYER,
        label: wallet.GroupName,
        meta: _objectSpread(_objectSpread({}, wallet), {}, {
          oneTimeUse: true
        })
      };
    }
  }).addCase(fetchMarketplaceRetailWallet.fulfilled, (state, action) => {
    var _state$wallets$WALLET4;
    delete state.requests[action.meta.requestId];
    const currentCommission = (_state$wallets$WALLET4 = state.wallets[WALLET_ID_MARKETPLACE_RETAILER]) === null || _state$wallets$WALLET4 === void 0 ? void 0 : _state$wallets$WALLET4.meta.commissionPercent;
    state.wallets[WALLET_ID_MARKETPLACE_RETAILER] = {
      id: WALLET_ID_MARKETPLACE_RETAILER,
      type: WALLET_MARKETPLACE_RETAILER,
      label: action.payload.name,
      meta: _objectSpread(_objectSpread({}, action.payload), {}, {
        commissionPercent: typeof currentCommission === 'number' ? Math.min(currentCommission, action.payload.commissionPercent) : action.payload.commissionPercent,
        apiCommission: action.payload.commissionPercent,
        oneTimeUse: true
      })
    };
  }).addCase(setRetailerCommission, (state, action) => {
    const {
      walletId,
      commission
    } = action.payload;
    state.wallets[walletId].meta.commissionPercent = commission;
  }).addMatcher(isPendingWalletAction, (state, action) => {
    state.requests[action.meta.requestId] = action.meta.requestStatus;
  }).addMatcher(isRejectedWalletAction, (state, action) => {
    delete state.requests[action.meta.requestId];
  });
});
setGlobal('svs.components.tipsen.wallet.walletReducer', walletReducer);

 })(window);